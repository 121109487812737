<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <h3>Telefones úteis</h3>
    <ul>
      <li><a href="tel:1126183874">(11) 2618-3874</a></li>
      <li><a href="tel:1126184572">(11) 2618-4572</a></li>
    </ul>
    <h3>Emails úteis</h3>
    <ul>
      <li>
        <a href="mailto: comercial@esquadrilife.com.br"
          >comercial@esquadrilife.com.br</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  border-radius: 100%;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
}
</style>
