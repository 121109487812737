<template>
  <div class="home">
    <img alt="Vue logo" width="100px" src="../assets/logo.svg" />
    <HelloWorld msg="Um novo conceito em esquadrias. Em breve." />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
};
</script>
